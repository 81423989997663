.m-pagination {
  margin: $line-height-computed 0;
  text-align: center;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__list-item {
    display: inline-block;
    margin: 0 3px;
  }

  &__item {
    display: block;
    padding: $padding-base-vertical $padding-base-horizontal;
    border: 1px solid $pagination-border;
    border-radius: $border-radius-base;

    @at-root .m-pagination--pager & {
      border-radius: $pager-border-radius;
    }

    &.is-active,
    &:hover,
    &:focus {
      text-decoration: none;
      background-color: $pagination-hover-bg;
    }

    &.is-disabled {
      pointer-events: none;
      cursor: $cursor-disabled;
      opacity: 0.6;
    }
  }

  &__icon {
    vertical-align: middle;
  }
}
